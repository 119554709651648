
import CardTable from '@/components/kt-datatable/CardTable.vue';

export default {
  name: 'PartnerCarLists',
  components: {
    CardTable,
  },
  setup() {
    const breadcrumbs = ['Partner Car', ['Partner Car Verification']];
    const tableHeader = [
      {
        name: 'No.',
        key: 'no',
        sortable: true,
      },
      {
        name: 'Image',
        key: 'images',
        sortable: true,
      },
      {
        name: 'Type',
        key: 'typeName',
        sortable: true,
      },
      {
        name: 'Brand',
        key: 'brandName',
        sortable: true,
      },
      {
        name: 'Model',
        key: 'modelName',
        sortable: true,
      },
      {
        name: 'License',
        key: 'plate',
        sortable: true,
      },
      {
        name: 'Date Created',
        key: 'created',
        sortable: true,
      },
      {
        name: 'Verification Details',
        key: 'details',
        sortable: false,
      },
    ];

    const loopers = [
      'plate',
      'created',
      'no',
      'typeName',
      'brandName',
      'modelName',
    ];

    const customProperties = [
      { key: 'typeName', value: ['type', 'name'] },
      { key: 'brandName', value: ['brand', 'name'] },
      { key: 'modelName', value: ['model', 'name'] },
    ];

    return { tableHeader, breadcrumbs, loopers, customProperties };
  },
};
